import { User } from "@src/store/slices/login/types";
import { EventProperties } from "@src/utils/analytics";
import jwtDecode from "jwt-decode";
import { useState, useEffect } from "react";

import { useCookies } from "./useCookies";
import { useWindowName } from "./useWindowName";

export const useSession = () => {
  const [infoUser, setInfoUser] = useState<EventProperties>();
  const [userId, setUserId] = useState<string>();
  const cookiesStore = useCookies();
  const { windowName } = useWindowName();

  useEffect(() => {
    const tokenKey = `${process.env.NEXT_PUBLIC_PLATAFORM_NAME}-Access-Token`;
    const token = cookiesStore.get(tokenKey);
    if (token && token != "null") {
      const decodedUser = jwtDecode<User>(token);
      const identifyUser: EventProperties = {
        solutionshub_id: decodedUser.id,
        name: decodedUser.name,
        email: decodedUser.email,
        customer: decodedUser.customerName,
        phone: decodedUser.phone,
        sessionId: windowName,
        sessionStart: sessionStorage.getItem("sessionStart"),
        department: decodedUser.departmentName || "",
      };

      setInfoUser(identifyUser);
      setUserId(identifyUser.solutionshub_id);
    }
  }, []);
  return { infoUser, userId };
};

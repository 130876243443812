import Alert from "@src/components/common/Alert";
import Button from "@src/components/common/Button";
import Footer from "@src/components/common/footer";
import Input from "@src/components/common/Input";
import InputPassword from "@src/components/common/InputPassword";
import Label from "@src/components/common/Label";
import LinkHilight from "@src/components/common/LinkHilight";
import { PrivacyPopUpMessageLogin } from "@src/components/messages/privacy/privacy-pop-up-login";
import { CLICK_ACCESS, LOGIN } from "@src/constants/constant";
import { ONE_DAY } from "@src/constants/time";
import { login } from "@src/services/login";
import { ExceptionsConstants } from "@src/shared/enums/exceptions";
import { loginActions } from "@src/store/slices/login";
import {
  selectAuthToken,
  selectAuthUser,
  selectExistingCpfError,
  selectLoginError,
  selectLoginLoading,
  selectLoginMsgError,
  selectPrivacyPopUpCanceled,
} from "@src/store/slices/login/selectors";
import { User } from "@src/store/slices/login/types";
import { useAnalytics } from "@src/utils/analytics";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import style from "./login.module.scss";

import { useCookies } from "@src/hooks/useCookies";
import { useSession } from "@src/hooks/useSession";
import { useWindowName } from "@src/hooks/useWindowName";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";

export default function Login() {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const password = watch("password");
  const email = watch("email");

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectLoginLoading);
  const hasError = useAppSelector(selectLoginError);
  const msgError = useAppSelector(selectLoginMsgError);
  const privacyPopUpCanceled = useAppSelector(selectPrivacyPopUpCanceled);
  const existingCpfError = useAppSelector(selectExistingCpfError);
  const router = useRouter();
  const authToken = useAppSelector(selectAuthToken);
  const user: User = useAppSelector(selectAuthUser) || ({ name: "", email: "", roles: [] } as User);
  const cookies = useCookies();
  const session = useSession();
  const [inactiveUserError, setInactiveUserError] = useState<string | null>(null);
  const [userIdToPrivacy, setUserIdToPrivacy] = useState(null);

  const [showPrivacyModal, setshowPrivacyModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showExistingCpfAlert, setShowExistingCpfAlert] = useState(false);
  const [cpfRegistered, setCpfRegistered] = useState("");
  const [phoneRegistered, setPhoneRegistered] = useState("");
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  cookies.set(
    (process.env.NEXT_PUBLIC_PLATAFORM_NAME + "-Access-Token") as string,
    authToken,
    ONE_DAY,
  );
  cookies.set("signedUser", JSON.stringify(user), ONE_DAY);
  const { userId, infoUser } = useSession();
  const { updateWindowName } = useWindowName();

  const { identifyUser, trackButtonClick, trackLogin } = useAnalytics();

  useEffect(() => {
    if (hasError) {
      if (msgError === ExceptionsConstants.INACTIVE_USER) {
        setInactiveUserError(msgError);
        setShowAlert(false);
        setShowExistingCpfAlert(false);
        return;
      }
      setShowAlert(false);
      setShowExistingCpfAlert(false);
      setInactiveUserError(null);
    }
  }, [hasError, msgError]);

  useEffect(() => {
    if (!session.userId) return;
    trackLogin(LOGIN, { solutionshub_id: userId });
  }, [session.userId, trackLogin]);

  useEffect(() => {
    if (privacyPopUpCanceled) setShowAlert(true);
    if (existingCpfError) setShowExistingCpfAlert(true);
  }, [privacyPopUpCanceled, existingCpfError]);

  const plataformName = `${process.env.NEXT_PUBLIC_PLATAFORM_NAME}`;

  const handleAcceptPrivacy = async () => {
    await onSubmit({ email: email, password: password, rememberMe: true });
  };

  const handleCancelTerms = (term: boolean, existingCpf: boolean) => {
    setshowPrivacyModal(false);
    if (existingCpf) {
      setShowExistingCpfAlert(true);
      return;
    }
    setShowAlert(!term);
  };

  const onSubmit = async (data: any) => {
    try {
      dispatch(loginActions.controlView({ privacyPopUpCanceled: false }));
      const { privacy, token } = await dispatch(login(data));
      trackButtonClick(CLICK_ACCESS, { solutionshub_id: userId });

      const isPersonalDataRegistered =
        (!!token.cpf || token.cpf !== "") && (!!token.phone || token.phone !== "");
      const isPrivacyAccepted = privacy.assign;

      setCpfRegistered(token.cpf);
      setPhoneRegistered(token.phone);
      setIsPrivacyAccepted(isPrivacyAccepted);

      if (!isPersonalDataRegistered || !isPrivacyAccepted) {
        setShowAlert(false);
        setShowExistingCpfAlert(false);
        setshowPrivacyModal(true);
        setUserIdToPrivacy(token.id);

        dispatch(
          loginActions.controlData({
            token: null,
            user: null,
          }),
        );

        return;
      }

      updateWindowName();

      identifyUser("LoginEfetuado", {
        solutionshub_id: token.id,
        name: token.name,
        email: token.email,
        customer: token.customerName,
        phone: token.phone,
        department: token.departmentName,
      });

      router.push("/home");
    } catch (err: any) {
      console.error(err);
    }
  };

  const logoImagem = (
    <Image
      src={"/logoLogin.svg"}
      alt="logo imagem"
      width={168}
      height={80}
      style={{ margin: "0 auto" }}
      priority
    />
  );

  return (
    <div className={style.login}>
      <Head>
        <title>{`${plataformName} | Login`}</title>
      </Head>
      <div className={style.login__container}>
        {showPrivacyModal && (
          <PrivacyPopUpMessageLogin
            onClose={handleCancelTerms}
            onAccept={handleAcceptPrivacy}
            userId={userIdToPrivacy}
            isPrivacyAccepted={isPrivacyAccepted}
            cpfRegistered={cpfRegistered}
            phoneRegistered={phoneRegistered}
            show={showPrivacyModal}
          />
        )}
        <div className={style.login__container__image}>
          <div
            style={{
              display: "table",
              background: `url('/binary.png')`,
              width: "100%",
              height: "100%",
              backgroundSize: "cover",
              backgroundPosition: "30% 45%",
            }}
          ></div>
        </div>
        <div className={style.login__container__content}>
          <div className={style.login__content}>
            <div className={style.login__welcome}>
              <div className={style.login__logo}>{logoImagem}</div>

              {showAlert ? (
                <Alert type="error" className={`${style.login__alert} min-w-[30rem]`}>
                  Cadastro incompleto! Para acessar a plataforma, complete seu cadastro com as
                  informações obrigatórias. Faça login para continuar de onde parou.
                </Alert>
              ) : showExistingCpfAlert ? (
                <Alert type="error" className={`${style.login__alert} min-w-[30rem]`}>
                  Este CPF já está cadastrado na plataforma. Se você ainda não acessou sua conta,
                  verifique seu e-mail para ativá-la. Caso já tenha uma conta, faça login para
                  continuar.
                </Alert>
              ) : inactiveUserError ? (
                <Alert type="error" className={style.login__alert}>
                  Seu acesso está bloqueado. Entre em contato com o suporte para mais informações.
                </Alert>
              ) : hasError ? (
                <Alert type="error" className={style.login__alert}>
                  Acesso inválido! Verifique seus dados de acesso.
                </Alert>
              ) : errors.email || errors.password ? (
                <Alert type="error" className={style.login__alert}>
                  Preencha os campos obrigatórios.
                </Alert>
              ) : null}

              <h2>Bem-vindo!</h2>
            </div>
            <p className={style.login__info}>
              Informe os dados abaixo para acompanhar suas soluções
            </p>
            <div className={style.login__formlogin}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full">
                  <Label>Login</Label>
                  <Input
                    error={Boolean(errors.email) || hasError || !!inactiveUserError}
                    type="email"
                    placeholder="Digite seu e-mail"
                    id="email"
                    name="email"
                    {...register("email", { required: true })}
                  />
                </div>
                <div className="relative mt-5">
                  <Label>Senha</Label>
                  <InputPassword
                    error={Boolean(errors.password) || hasError || !!inactiveUserError}
                    placeholder="Digite sua senha"
                    id="password"
                    name="password"
                    {...register("password", { required: true })}
                  />
                </div>
                <div className="mb-1 mt-6">
                  <Button
                    onClick={() => trackButtonClick(CLICK_ACCESS, { email: email })}
                    isLoading={isLoading}
                    disabled={isLoading}
                    size="medium"
                    variant="afya"
                    full
                  >
                    Acessar
                  </Button>
                </div>
                <div className={`${style.login__formlogin__btn} font-dmSans`}>
                  <LinkHilight
                    href="/forgot-password"
                    onClick={() =>
                      dispatch(
                        loginActions.controlView({
                          hasError: false,
                          privacyPopUpCanceled: false,
                          existingCpfError: false,
                        }),
                      )
                    }
                  >
                    Esqueceu a senha?
                  </LinkHilight>
                </div>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default function Error() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      className="fill-system-error-500"
    >
      <path d="M7.99998 1.58105C4.32398 1.58105 1.33331 4.57172 1.33331 8.24772C1.33331 11.9237 4.32398 14.9144 7.99998 14.9144C11.676 14.9144 14.6666 11.9237 14.6666 8.24772C14.6666 4.57172 11.676 1.58105 7.99998 1.58105ZM10.8046 10.1097L9.86198 11.0524L7.99998 9.19039L6.13798 11.0524L5.19531 10.1097L7.05731 8.24772L5.19531 6.38572L6.13798 5.44305L7.99998 7.30506L9.86198 5.44305L10.8046 6.38572L8.94265 8.24772L10.8046 10.1097Z" />
    </svg>
  );
}
